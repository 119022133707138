<template>
    <div id="preloader">
        <div class="center">
                <img class="loading_logo" src="@/assets/img/fa-loader.svg" alt="">
            <div class="loading_bar">
                <div class="inner_bar"></div>
            </div>
        </div>
        <div class="overlay">
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
        </div>
    </div>
</template>